/** @jsxImportSource theme-ui */
import React, { useState } from "react";
import { graphql } from "gatsby";
import Layout from "../components/layout";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronDown, faChevronUp } from "@fortawesome/free-solid-svg-icons";
import { Box, Container, Divider, Flex, Heading, IconButton } from "theme-ui";
import ThemedMarkdown from "../components/themed-markdown";
import { GatsbyImage, getImage } from "gatsby-plugin-image";

type OpeningProps = {
  heading: string;
  image: any;
  alttext: string;
  text: string;
  text2: string;
};

const Opening = (opening: OpeningProps) => {
  const [open, setOpen] = useState(false);
  return (
    <Box my={3}>
      <Flex
        sx={{
          display: "inline-flex",
          flexDirection: "row",
          alignItems: "center",
          ":hover": { cursor: "pointer" },
        }}
        onClick={() => setOpen(!open)}
        mt={4}
      >
        <Box mr={3}>
          <IconButton>
            <FontAwesomeIcon
              icon={open ? faChevronUp : faChevronDown}
              size="xl"
              sx={{ color: "primaryLight" }}
            />
          </IconButton>
        </Box>
        <Box>
          <Heading
            as="h2"
            variant="styles.h4"
            sx={{
              color: "primary",
            }}
          >
            {opening.heading}
          </Heading>
        </Box>
      </Flex>
      {!!open && (
        <Box pl={3} sx={{ borderLeft: (theme) => `solid 1px ${theme.colors.divider}` }}>
          <Flex
            sx={{
              flexDirection: ["column", "column", "row"],
              flexFlow: ["column-reverse", "column-reverse", "default"],
            }}
          >
            <Box sx={{ flex: "3 3 0", my: 3 }}>
              <ThemedMarkdown html={opening.text} />
            </Box>
            <Box sx={{ flex: "1 1 0", my: 3 }}>
              <GatsbyImage image={getImage(opening.image)} alt={opening.alttext} />
            </Box>
          </Flex>
          <ThemedMarkdown html={opening.text2} />
        </Box>
      )}
    </Box>
  );
};

export const CareerPage = ({ data }) => {
  const { frontmatter } = data.markdownRemark;
  return (
    <Layout
      activeNav="karriere"
      seo={{ title: frontmatter.title, description: frontmatter.seoDescription }}
    >
      <Container my={5}>
        <Heading as="h1" variant="styles.h2" mb={2}>
          Stellenangebote
        </Heading>
        {/* <Flex sx={{ flexDirection: "column" }}>
          <GatsbyImage
            sx={{ flex: "1 1 0", my: 4 }}
            image={getImage(frontmatter.heroblockSingle.image)}
            alt={frontmatter.heroblockSingle.alttext}
          />
          <ThemedMarkdown html={frontmatter.heroblockSingle.markdown} />
        </Flex> */}
        <Box mb={4}>
          {frontmatter.openings.map((opening) => (
            <Opening key={opening.heading} {...opening} />
          ))}
        </Box>
        <Divider />
        <ThemedMarkdown html={frontmatter.directions} />
      </Container>
    </Layout>
  );
};

export default CareerPage;

export const pageQuery = graphql`
  query CareerPageTemplate {
    markdownRemark(frontmatter: { templateKey: { eq: "career-page" } }) {
      frontmatter {
        title
        seoDescription
        heroblockSingle {
          image {
            childImageSharp {
              gatsbyImageData(quality: 92, layout: CONSTRAINED, placeholder: BLURRED)
            }
          }
          alttext
          markdown
        }
        openings {
          heading
          image {
            childImageSharp {
              gatsbyImageData(quality: 92, layout: CONSTRAINED, placeholder: BLURRED)
            }
          }
          alttext
          text
          text2
        }
        directions
      }
    }
  }
`;
